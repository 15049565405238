<template>
  <svg
    class="group"
    viewBox="0 0 275 275"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 57.1777C0 25.6975 25.5198 0.177734 57 0.177734H218C249.48 0.177734 275 25.6975 275 57.1777V218.178C275 249.658 249.48 275.178 218 275.178H57C25.5198 275.178 0 249.658 0 218.178V57.1777Z"
      :class="props.backgroundClass"
      fill="currentColor"
    />
    <g clip-path="url(#clip0_7146_20188)" filter="url(#filter0_i_7146_20188)">
      <path
        d="M170.792 147.538L174.854 121.083H149.468V103.916C149.468 96.6796 153.013 89.6217 164.383 89.6217H175.923V67.1002C175.923 67.1002 165.452 65.314 155.439 65.314C134.534 65.314 120.871 77.9863 120.871 100.924V121.088H97.6318V147.543H120.871V211.498H149.468V147.543L170.792 147.538Z"
        :class="props.iconClass"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_7146_20188"
        x="63.6846"
        y="65.3096"
        width="146.184"
        height="150.184"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_7146_20188"
        />
      </filter>
      <clipPath id="clip0_7146_20188">
        <rect
          width="146.184"
          height="146.184"
          fill="white"
          transform="translate(63.6846 65.3096)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup>
const props = defineProps({
  backgroundClass: {
    type: String,
    required: false,
    default: "text-[#2D2D2D] group-hover:text-white",
  },
  iconClass: {
    type: String,
    required: false,
    default: "text-white group-hover:text-primary",
  },
});
</script>
