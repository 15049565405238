<template>
  <svg
    class="group"
    viewBox="0 0 275 275"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.177734 57C0.177734 25.5198 25.6975 0 57.1777 0H218.178C249.658 0 275.178 25.5198 275.178 57V218C275.178 249.48 249.658 275 218.178 275H57.1777C25.6975 275 0.177734 249.48 0.177734 218V57Z"
      fill="currentColor"
      :class="props.backgroundClass"
    />
    <g filter="url(#filter0_i_7146_20149)">
      <g filter="url(#filter1_i_7146_20149)">
        <path
          d="M172.556 85H191.513L150.097 132.336L198.82 196.75H160.67L130.79 157.683L96.6001 196.75H77.6312L121.93 146.119L75.1899 85H114.308L141.317 120.708L172.556 85ZM165.902 185.403H176.407L108.6 95.7509H97.3279L165.902 185.403Z"
          fill="currentColor"
          :class="props.iconClass"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_i_7146_20149"
        x="63.4321"
        y="68.0269"
        width="146.184"
        height="150.184"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_7146_20149"
        />
      </filter>
      <filter
        id="filter1_i_7146_20149"
        x="75.1899"
        y="85"
        width="123.63"
        height="115.75"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_7146_20149"
        />
      </filter>
    </defs>
  </svg>
</template>
<script setup>
const props = defineProps({
  backgroundClass: {
    type: String,
    required: false,
    default: "text-[#2D2D2D] group-hover:text-white",
  },
  iconClass: {
    type: String,
    required: false,
    default: "text-white group-hover:text-primary",
  },
});
</script>
