<template>
  <footer
    class="bg-center bg-no-repeat bg-secondary footer"
    aria-labelledby="footerHeading"
  >
    <div class="container py-[30px] lg:pt-[37px] lg:pb-16">
      <section
        class="flex flex-col items-center justify-between xl:gap-8 lg:flex-row"
      >
        <!-- Logo -->
        <div class="py-2 xl:pr-11">
          <nuxt-link
            :aria-label="$t('common.home_title')"
            :to="$i18nPath('/')"
            :prefetch="false"
          >
            <FooterLogo class="w-[189px] h-[34px]" />
          </nuxt-link>
        </div>

        <!-- Social networks -->
        <div
          v-if="
            checkSocialExist &&
            currentSiteSettings &&
            currentSiteSettings.socials
          "
          class="flex flex-col items-center justify-between w-full mt-[36px] lg:items-start xl:items-center lg:mt-0 xl:gap-[22px] xl:flex-row lg:w-auto lg:justify-end"
        >
          <p
            class="text-sm !leading-[18px] font-normal lg:ml-2 font-ff-good-pro-cond text-white"
          >
            {{ $t("common.find_us") }}
          </p>
          <div
            class="flex items-center justify-center mt-4 space-x-3 lg:mt-[10px] xl:mt-0 md:justify-end"
          >
            <a
              v-if="currentSiteSettings.socials.facebook"
              :href="currentSiteSettings.socials.facebook"
              target="_blank"
              aria-label="Facebook link"
              class="flex gap-3 text-white"
            >
              <IconsFacebook class="w-10 h-10 lg:w-12 lg:h-12" />
            </a>
            <a
              v-if="currentSiteSettings.socials.instagram"
              :href="currentSiteSettings.socials.instagram"
              target="_blank"
              aria-label="Instagram link"
              class="flex gap-3 text-white"
            >
              <IconsInstagram class="w-10 h-10 lg:w-12 lg:h-12" />
            </a>
            <a
              v-if="currentSiteSettings.socials.linkedin"
              :href="currentSiteSettings.socials.linkedin"
              target="_blank"
              aria-label="LinkedIn link"
              class="flex gap-3 text-white"
            >
              <IconsLinkedIn class="w-10 h-10 lg:w-12 lg:h-12" />
            </a>
            <a
              v-if="currentSiteSettings.socials.twitter"
              :href="currentSiteSettings.socials.twitter"
              target="_blank"
              aria-label="Twitter link"
              class="flex gap-3 text-white"
            >
              <IconsTwitter class="w-10 h-10 lg:w-12 lg:h-12" />
            </a>
            <a
              v-if="currentSiteSettings.socials.youtube"
              :href="currentSiteSettings.socials.youtube"
              target="_blank"
              aria-label="YouTube link"
              class="flex gap-3 text-white"
            >
              <IconsYouTube class="w-10 h-10 lg:w-12 lg:h-12" />
            </a>
            <a
              v-if="currentSiteSettings.socials.tiktok"
              :href="currentSiteSettings.socials.tiktok"
              target="_blank"
              aria-label="TikTok link"
              class="flex gap-3 text-white"
            >
              <IconsTikTok class="w-10 h-10 lg:w-12 lg:h-12" />
            </a>
          </div>
        </div>
      </section>

      <div
        v-if="hasMenuDisplay"
        class="overflow-hidden pb-0 lg:grid lg:grid-cols-11 lg:gap-7 mt-[38px] lg:mt-[66px] lg:px-9"
      >
        <!-- Brand -->
        <Collapsible
          :aria-label="$config.APP_NAME"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          active-class="border-opacity-100"
          inactive-class="border-opacity-30"
          class="col-span-3 py-3 border-b border-white border-solid lg:border-none lg:py-0"
        >
          <template #trigger="{ isActive }">
            <div class="flex justify-between">
              <h3
                class="inline uppercase font-bold !leading-[22px] font-ff-good-pro-cond text-white lg:text-primary lg:!leading-[26px] text-base my-0"
              >
                {{ `${$config.APP_NAME} ${countryName}` }}
              </h3>
              <AngleDown
                class="inline w-5 h-5 ml-1 text-white lg:hidden"
                :class="isActive ? 'rotate-180' : ' '"
              />
            </div>
          </template>

          <ul class="mt-0">
            <!-- eslint-disable-next-line -->
            <li v-if="currentSite && currentSite.news">
              <nuxt-link
                :to="$i18nPath(store.pageLinks['news'])"
                class="text-base leading-[20px] uppercase py-2 font-ff-good-pro-cond text-white hover:no-underline hover:opacity-90"
                :prefetch="false"
              >
                {{ $t("common.news") }}
              </nuxt-link>
            </li>
            <li>
              <nuxt-link
                :to="$i18nPath(store.pageLinks['stores'])"
                class="text-base leading-[20px] uppercase py-2 font-ff-good-pro-cond text-white hover:no-underline hover:opacity-90"
                :prefetch="false"
              >
                {{ $t("common.stores") }}
              </nuxt-link>
            </li>
            <!-- External links for Keeway Hungry -->
            <li v-if="store.country === 'hu'">
              <a
                href="https://alkatresz.keeway.hu/"
                target="_blank"
                class="text-base leading-[20px] uppercase py-2 font-ff-good-pro-cond text-white hover:no-underline hover:opacity-90"
              >
                Alkatresz
              </a>
            </li>
            <li v-if="store.country === 'hu'">
              <a
                href="https://keeway.hu/alkatreszkatalogusok"
                target="_blank"
                class="text-base leading-[20px] uppercase py-2 font-ff-good-pro-cond text-white hover:no-underline hover:opacity-90"
              >
                Alkatresz Katalogusok
              </a>
            </li>
            <li
              v-if="
                currentSiteSettings && currentSiteSettings.test_ride_inquiry
              "
            >
              <nuxt-link
                :to="$i18nPath(store.pageLinks['test-drive'])"
                class="text-base leading-[20px] uppercase py-2 font-ff-good-pro-cond text-white hover:no-underline hover:opacity-90"
                :prefetch="false"
              >
                {{ $t("common.test_ride_inquiry.title") }}
              </nuxt-link>
            </li>
            <li v-if="store.country === 'hu'">
              <a
                href="https://benelliaruhaz.hu/letoltesek/"
                target="_blank"
                class="text-base leading-[20px] uppercase py-2 font-ff-good-pro-cond text-white hover:no-underline hover:opacity-90"
              >
                Letöltések
              </a>
            </li>
            <li
              v-if="currentSiteSettings && currentSiteSettings.become_a_partner"
            >
              <nuxt-link
                v-if="store.country === 'int'"
                :to="$i18nPath(store.pageLinks['become-a-distributor'])"
                class="text-base leading-[20px] uppercase py-2 font-ff-good-pro-cond text-white hover:no-underline hover:opacity-90"
                :prefetch="false"
              >
                {{ $t("common.become_a_distributor") }}
              </nuxt-link>
              <nuxt-link
                v-else
                :to="$i18nPath(store.pageLinks['become-a-dealer'])"
                class="text-base leading-[20px] uppercase py-2 font-ff-good-pro-cond text-white hover:no-underline hover:opacity-90"
                :prefetch="false"
              >
                {{ $t("common.become_a_dealer") }}
              </nuxt-link>
            </li>
          </ul>
        </Collapsible>

        <!-- Company -->
        <Collapsible
          :aria-label="$t('common.company')"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          active-class="border-opacity-100"
          inactive-class="border-opacity-30"
          class="col-span-2 pt-6 pb-3 border-b border-white border-solid lg:border-none lg:py-0"
        >
          <template #trigger="{ isActive }">
            <div class="flex justify-between">
              <h3
                class="inline uppercase font-bold !leading-[22px] font-ff-good-pro-cond text-white lg:text-primary lg:!leading-[26px] text-base my-0"
                v-text="$t('common.company')"
              />
              <AngleDown
                class="inline w-5 h-5 ml-1 text-white lg:hidden"
                :class="isActive ? 'rotate-180' : ' '"
              />
            </div>
          </template>

          <ul class="my-0">
            <li>
              <nuxt-link
                :to="$i18nPath(store.pageLinks['about-us'])"
                class="text-base leading-[20px] uppercase py-2 font-ff-good-pro-cond text-white hover:no-underline hover:opacity-90"
                :prefetch="false"
              >
                {{ $t("common.about_us") }}
              </nuxt-link>
            </li>

            <li>
              <nuxt-link
                v-if="currentSiteSettings && currentSiteSettings.work_with_us"
                :to="$i18nPath(store.pageLinks['work-with-us'])"
                class="text-base leading-[20px] uppercase py-2 font-ff-good-pro-cond text-white hover:no-underline hover:opacity-90"
                :prefetch="false"
              >
                {{
                  $t("common.work_with_brand", {
                    brand: "BBike ",
                    interpolation: { prefix: "[:", suffix: "]" },
                  })
                }}
              </nuxt-link>
            </li>
          </ul>
        </Collapsible>

        <!-- Customer service -->
        <Collapsible
          :aria-label="$t('common.customer_services')"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          active-class="border-opacity-100"
          inactive-class="border-opacity-30"
          class="col-span-2 pt-6 pb-3 border-b border-white border-solid lg:border-none lg:py-0"
        >
          <template #trigger="{ isActive }">
            <div class="flex justify-between text-left">
              <h3
                class="inline uppercase font-bold !leading-[22px] font-ff-good-pro-cond text-white lg:text-primary lg:!leading-[26px] text-base my-0"
                v-text="$t('common.customer_services')"
              />
              <AngleDown
                class="inline w-5 h-5 ml-1 text-white lg:hidden"
                :class="isActive ? 'rotate-180' : ' '"
              />
            </div>
          </template>

          <ul class="my-0">
            <li
              v-if="
                currentSiteSettings && currentSiteSettings.intercom.integrate
              "
            >
              <a
                href=""
                :aria-label="$t('common.chat_now')"
                class="text-base leading-[20px] uppercase py-2 font-ff-good-pro-cond text-white hover:no-underline hover:opacity-90"
                @click.prevent="intercom.show()"
                v-text="$t('common.chat_now')"
              />
            </li>

            <li>
              <nuxt-link
                :to="$i18nPath(store.pageLinks['contact-us'])"
                class="text-base leading-[20px] uppercase py-2 font-ff-good-pro-cond text-white hover:no-underline hover:opacity-90"
                :prefetch="false"
              >
                {{ $t("common.contact_us") }}
              </nuxt-link>
            </li>
            <li>
              <nuxt-link
                :to="$i18nPath(store.pageLinks['calculate-bike-size'])"
                class="text-base leading-[20px] uppercase py-2 font-ff-good-pro-cond text-white hover:no-underline hover:opacity-90"
                :prefetch="false"
              >
                {{ $t("common.calculate_bike_size") }}
              </nuxt-link>
            </li>
          </ul>
        </Collapsible>

        <!-- Legal -->
        <Collapsible
          :aria-label="$t('common.legal')"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          active-class="border-opacity-100"
          inactive-class="border-opacity-30"
          class="col-span-2 pt-6 pb-3 border-b border-white border-solid lg:pl-8 lg:border-none lg:py-0"
        >
          <template #trigger="{ isActive }">
            <div class="flex justify-between text-left">
              <h3
                class="inline uppercase font-bold !leading-[22px] font-ff-good-pro-cond text-white lg:text-primary lg:!leading-[26px] text-base my-0"
                v-text="$t('common.legal')"
              />
              <AngleDown
                class="inline w-5 h-5 ml-1 text-white lg:hidden"
                :class="isActive ? 'rotate-180' : ' '"
              />
            </div>
          </template>

          <ul class="my-0">
            <li>
              <nuxt-link
                :to="$i18nPath(store.pageLinks['warranty'])"
                class="text-base leading-[20px] uppercase py-2 font-ff-good-pro-cond text-white hover:no-underline hover:opacity-90"
                :prefetch="false"
              >
                {{ $t("common.bikes_warranty") }}
              </nuxt-link>
            </li>
          </ul>
        </Collapsible>

        <!-- Group companies -->
        <Collapsible
          v-if="groupCompanies.length"
          :aria-label="$t('common.group_companies')"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          active-class="border-opacity-100"
          inactive-class="border-opacity-30"
          class="flex flex-col items-start col-span-2 pt-6 pb-3 border-b border-white border-solid lg:items-end lg:border-none lg:py-0"
        >
          <template #trigger="{ isActive }">
            <div class="flex justify-between lg:hidden">
              <h3
                class="inline uppercase font-bold !leading-[22px] font-ff-good-pro-cond text-white lg:text-primary lg:!leading-[26px] text-base my-0"
                v-text="$t('common.group_companies')"
              />
              <AngleDown
                class="inline w-5 h-5 ml-1 text-white lg:hidden"
                :class="isActive ? 'rotate-180' : ' '"
              />
            </div>
          </template>

          <div>
            <h3
              class="hidden lg:inline-block uppercase font-bold !leading-[22px] font-ff-good-pro-cond text-primary text-base my-0"
              v-text="$t('common.group_companies')"
            />
            <ul class="my-0">
              <li v-for="(company, index) in groupCompanies" :key="index">
                <a
                  :href="company.url"
                  target="_blank"
                  class="text-base leading-[20px] uppercase py-2 font-ff-good-pro-cond text-white hover:no-underline hover:opacity-90"
                >
                  {{ company.name }}
                </a>
              </li>
            </ul>
          </div>
        </Collapsible>
      </div>
      <section
        class="flex flex-col items-center justify-end lg:gap-8 lg:flex-row mt-[60px] lg:mt-[110px]"
      >
        <div class="flex-1">
          <!-- <div class="mt-12 md:mt-0">
            <img
              class="h-[130px] lg:h-[100px]"
              src="@/assets/images/qrcode/wechat.png"
              alt="WeChat QRCode"
            />
          </div> -->
        </div>

        <div class="flex items-center justify-center flex-1">
          <FlagWebDark class="lg:w-[236px] lg:h-[12px] w-[196px] h-[10px]" />
        </div>
        <div class="flex-1"></div>
      </section>
      <!-- Footer below border -->
      <div
        class="lg:px-9 relative flex flex-row items-center justify-between gap-8 pt-8 pb-24 mt-[26px] lg:mt-[30px] border-t border-primary md:gap-4 md:pb-0"
      >
        <div class="h-12">
          <KeewayGroupLogo class="text-dark-gray-300" />
        </div>

        <div
          class="absolute bottom-0 left-0 right-0 flex flex-col-reverse gap-4 mx-2 text-base leading-6 sm:mx-4 md:mx-auto xl:pl-20 md:flex-col md:gap-0 md:relative md:bottom-auto md:right-auto md:left-auto"
        >
          <!-- Copyright -->
          <p
            class="font-normal text-center text-dark-gray-300 font-ff-good-pro-cond"
          >
            Keeway Group &copy; {{ $t("common.copyright") }} - {{ year }} -
            {{ $t("common.all_rights_reserved") }}
          </p>

          <!-- Legal -->
          <div
            class="flex flex-row items-center justify-between gap-1 md:justify-around md:gap-4 font-ff-good-pro-cond"
          >
            <nuxt-link
              :to="$i18nPath(store.pageLinks['terms'])"
              class="text-dark-gray-300 hover:no-underline hover:opacity-90"
              :prefetch="false"
            >
              {{ $t("common.terms_and_conditions") }}
            </nuxt-link>
            <nuxt-link
              :to="$i18nPath(store.pageLinks['privacy-policy'])"
              class="text-dark-gray-300 hover:no-underline lg:mx-4 hover:opacity-90"
              :prefetch="false"
            >
              {{ $t("common.privacy_policy") }}
            </nuxt-link>
            <nuxt-link
              :to="$i18nPath(store.pageLinks['cookie-policy'])"
              class="text-dark-gray-300 hover:no-underline hover:opacity-90"
              :prefetch="false"
            >
              {{ $t("common.cookie_policy") }}
            </nuxt-link>
          </div>
        </div>

        <div>
          <!-- Locales -->
          <LocaleSelector
            class="flex justify-center md:block"
            name="footer"
            position="bottom"
          />
        </div>
      </div>
    </div>
    <WhatsappChat
      v-if="currentSiteSettings && currentSiteSettings.whatsapp"
      :phone="currentSiteSettings.whatsapp"
    />
    <MemeLink />
  </footer>
</template>
<script setup>
import { useMainStore } from "@/store/index";
import CloseIcon from "@/assets/svg/heroicons/x.svg?component";
import AngleDown from "@/assets/svg/heroicons/angle-down.svg?component";
import { useLocaleStore } from "@/store/locales";
const Slogan = defineAsyncComponent(() =>
  import("~/assets/svg/slogan.svg?component")
);
const FooterLogo = defineAsyncComponent(() =>
  import("~/assets/svg/footer-logo.svg?component")
);
const FlagWebDark = defineAsyncComponent(() =>
  import("~/assets/svg/FLAG_WEB_DARK.svg?component")
);

const store = useMainStore();
const nuxtApp = useNuxtApp();
const localeStore = useLocaleStore();

const $i18nPath = nuxtApp.$i18nPath;
const $config = nuxtApp.$config.public;
const browserWidth = ref(0);
const hasMenuDisplay = ref(false);
const state = reactive({ group_companies: [] });
const year = ref(new Date().getFullYear());
const intercom = ref(
  typeof nuxtApp.$intercom !== "undefined" ? nuxtApp.$intercom : null
);

const products = store.products;
const sites = store.sites;
const country = store.country;
const locales = localeStore.defaultLocales;
const currentSiteSettings = store.currentSiteSettings;
const currentSite = store.currentSite;
const features = store.features;

const textClass = computed(() => {
  return store.locale === "el"
    ? "text-[17px] 2xl:text-[20px] font-bold"
    : "text-[30px]";
});

const countryName = computed(() => {
  const countryData = locales.length
    ? locales.find((x) => x.slug === country)
    : null;
  return countryData ? countryData.country.name : "";
});

const checkSocialExist = computed(() => {
  let data = [
    "facebook",
    "instagram",
    "linkedin",
    "twitter",
    "youtube",
    "tiktok",
  ];
  if (!currentSiteSettings || !currentSiteSettings.socials) {
    return null;
  }
  return data.find((_v) => currentSiteSettings.socials[_v]);
});

const checkBrowserWidth = computed(() => {
  return browserWidth.value >= 1024 ? true : false;
});

const groupCompanies = computed(() => {
  const findData = store.features.find((_v) => _v.slug == "group-companies");
  if (findData && findData.settings) {
    return findData.settings;
  }
  return [];
});

onMounted(() => {
  const findData = features.find((_v) => _v.slug == "group-companies");

  if (findData && findData.settings) {
    state.group_companies = findData.settings;
  }
  browserWidth.value = window.innerWidth;
  hasMenuDisplay.value = true;
  window.addEventListener("resize", windowResized);
});

onUnmounted(() => {
  window.removeEventListener("resize", windowResized);
});

function windowResized() {
  browserWidth.value = window.innerWidth;
}
</script>

<style scoped>
.Collapsible:has(.Collapsible__trigger--open) {
  @apply !border-opacity-100;
}
.footer {
  background-image: url("/footer-bg-logo.svg");
  background-size: 100% 80%;
}
</style>
