<template>
  <svg
    class="group"
    viewBox="0 0 275 275"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.177734 57.1777C0.177734 25.6975 25.6975 0.177734 57.1777 0.177734H218.178C249.658 0.177734 275.178 25.6975 275.178 57.1777V218.178C275.178 249.658 249.658 275.178 218.178 275.178H57.1777C25.6975 275.178 0.177734 249.658 0.177734 218.178V57.1777Z"
      fill="currentColor"
      :class="props.backgroundClass"
    />
    <g clip-path="url(#clip0_7146_20198)" filter="url(#filter0_i_7146_20198)">
      <path
        d="M209.894 104.944C209.067 101.991 207.454 99.3177 205.229 97.2081C202.94 95.0336 200.135 93.4782 197.079 92.6886C185.64 89.6735 139.811 89.6735 139.811 89.6735C120.706 89.4561 101.605 90.4122 82.6167 92.5363C79.5602 93.3842 76.7606 94.9744 74.4669 97.1655C72.2133 99.3339 70.5809 102.008 69.7281 104.938C67.6797 115.973 66.6844 127.178 66.7557 138.402C66.6826 149.615 67.6755 160.817 69.7281 171.866C70.5626 174.783 72.1889 177.445 74.4487 179.595C76.7084 181.745 79.5225 183.298 82.6167 184.121C94.2079 187.13 139.811 187.13 139.811 187.13C158.941 187.347 178.066 186.391 197.079 184.267C200.135 183.477 202.94 181.922 205.229 179.747C207.482 177.597 209.084 174.923 209.888 172.012C211.99 160.981 213.012 149.771 212.94 138.542C213.098 127.265 212.077 116.002 209.894 104.938V104.944ZM125.242 159.257V117.552L163.371 138.408L125.242 159.257Z"
        fill="currentColor"
        :class="props.iconClass"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_7146_20198"
        x="66.7559"
        y="65.3093"
        width="146.184"
        height="150.184"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_7146_20198"
        />
      </filter>
      <clipPath id="clip0_7146_20198">
        <rect
          width="146.184"
          height="146.184"
          fill="white"
          transform="translate(66.7559 65.3093)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup>
const props = defineProps({
  backgroundClass: {
    type: String,
    required: false,
    default: "text-[#2D2D2D] group-hover:text-white",
  },
  iconClass: {
    type: String,
    required: false,
    default: "text-white group-hover:text-primary",
  },
});
</script>
