<template>
 <svg xmlns="http://www.w3.org/2000/svg" width="100" height="46" viewBox="0 0 100 46" fill="none">
  <path d="M15.9765 20.2921L15.6807 20.5862H13.6096L13.0179 20.2921L6.50895 12.3517H6.21309L4.14206 14.4103V20.2921L3.8462 20.5862H0.295861L0 20.2921V0.294088L0.295861 0H3.8462L4.14206 0.294088V8.82264H4.43792L13.0179 0.294088L13.6096 0H15.0889L15.3848 0.294088V3.23497L9.46757 9.11673V9.41081L15.9765 17.3512V20.2921Z" fill="#5E5E5E"/>
  <path d="M30.7511 13.234V14.4103L30.4552 14.7044H21.2835L20.9877 14.9985C20.9877 15.469 21.2244 15.9788 21.6977 16.5277C22.1711 17.0767 22.9206 17.3512 23.9463 17.3512C25.0311 17.3512 25.7215 17.0571 26.0173 16.4689L26.3132 16.1748H29.8635L30.1594 16.4689C30.0805 16.9591 29.9128 17.4394 29.6564 17.91C29.4197 18.3805 29.0745 18.851 28.6209 19.3216C28.1672 19.7921 27.5361 20.1744 26.7274 20.4685C25.9187 20.743 24.9917 20.8802 23.9463 20.8802C21.5597 20.8802 19.7253 20.1548 18.4433 18.704C17.1809 17.2532 16.5497 15.4298 16.5497 13.234C16.5497 11.0577 17.1612 9.24416 18.3841 7.79333C19.6267 6.32289 21.3822 5.58767 23.6504 5.58767C25.9187 5.58767 27.6643 6.32289 28.8872 7.79333C30.1298 9.24416 30.7511 11.0577 30.7511 13.234ZM26.0173 11.7635L26.3132 11.4694C26.3132 11.3518 26.2836 11.1851 26.2244 10.9695C26.1652 10.7538 26.0469 10.4989 25.8694 10.2049C25.7116 9.89116 25.4355 9.63628 25.041 9.44022C24.6662 9.22456 24.2027 9.11673 23.6504 9.11673C23.0981 9.11673 22.6248 9.22456 22.2303 9.44022C21.8555 9.63628 21.5794 9.89116 21.4019 10.2049C21.2441 10.4989 21.1356 10.7538 21.0764 10.9695C21.0173 11.1851 20.9877 11.3518 20.9877 11.4694L21.2835 11.7635H26.0173Z" fill="#5E5E5E"/>
  <path d="M46.7288 13.234V14.4103L46.4329 14.7044H37.2612L36.9653 14.9985C36.9653 15.469 37.202 15.9788 37.6754 16.5277C38.1488 17.0767 38.8983 17.3512 39.924 17.3512C41.0088 17.3512 41.6991 17.0571 41.995 16.4689L42.2908 16.1748H45.8412L46.137 16.4689C46.0581 16.9591 45.8905 17.4394 45.6341 17.91C45.3974 18.3805 45.0522 18.851 44.5986 19.3216C44.1449 19.7921 43.5137 20.1744 42.7051 20.4685C41.8964 20.743 40.9693 20.8802 39.924 20.8802C37.5373 20.8802 35.703 20.1548 34.4209 18.704C33.1586 17.2532 32.5274 15.4298 32.5274 13.234C32.5274 11.0577 33.1389 9.24416 34.3618 7.79333C35.6044 6.32289 37.3598 5.58767 39.6281 5.58767C41.8964 5.58767 43.6419 6.32289 44.8648 7.79333C46.1075 9.24416 46.7288 11.0577 46.7288 13.234ZM41.995 11.7635L42.2908 11.4694C42.2908 11.3518 42.2613 11.1851 42.2021 10.9695C42.1429 10.7538 42.0246 10.4989 41.8471 10.2049C41.6893 9.89116 41.4131 9.63628 41.0186 9.44022C40.6439 9.22456 40.1804 9.11673 39.6281 9.11673C39.0758 9.11673 38.6024 9.22456 38.208 9.44022C37.8332 9.63628 37.5571 9.89116 37.3795 10.2049C37.2218 10.4989 37.1133 10.7538 37.0541 10.9695C36.9949 11.1851 36.9653 11.3518 36.9653 11.4694L37.2612 11.7635H41.995Z" fill="#5E5E5E"/>
  <path d="M69.5182 9.11673L65.9678 20.2921L65.672 20.5862H61.8258L61.5299 20.2921L59.163 11.4694H58.8672L56.5003 20.2921L56.2044 20.5862H52.3582L52.0624 20.2921L48.512 9.11673V6.17585L48.8079 5.88176H51.4706L51.7665 6.17585L54.1334 14.4103H54.4293L56.7961 6.17585L57.092 5.88176H60.9382L61.2341 6.17585L63.601 14.4103H63.8968L66.2637 6.17585L66.5596 5.88176H69.2223L69.5182 6.17585V9.11673Z" fill="#5E5E5E"/>
  <path d="M84.3193 20.2921L84.0235 20.5862H80.769L80.4731 20.2921L80.1773 19.7039H79.8814C79.8025 19.7823 79.6743 19.8901 79.4968 20.0274C79.3193 20.1646 78.9445 20.3411 78.3725 20.5567C77.8203 20.7724 77.2384 20.8802 76.627 20.8802C74.9899 20.8802 73.6881 20.4685 72.7216 19.6451C71.7748 18.802 71.3014 17.6453 71.3014 16.1748C71.3014 15.3318 71.4494 14.5966 71.7452 13.9692C72.0608 13.3418 72.4454 12.8614 72.8991 12.5281C73.3527 12.1752 73.8853 11.891 74.4967 11.6753C75.1279 11.4596 75.6999 11.3224 76.2127 11.2636C76.7256 11.2048 77.2581 11.1753 77.8104 11.1753C78.1654 11.1753 78.5106 11.2048 78.8459 11.2636C79.2009 11.3028 79.4672 11.3518 79.6447 11.4106L79.8814 11.4694L80.1773 11.1753V10.8813C80.1773 10.1362 80.0491 9.65589 79.7927 9.44022C79.556 9.22456 78.9938 9.11673 78.1063 9.11673C77.7118 9.11673 77.4061 9.12653 77.1891 9.14613C76.9918 9.16574 76.7749 9.24416 76.5382 9.3814C76.3015 9.51865 76.1338 9.72451 76.0352 9.99899L75.7394 10.2931H72.189L71.8932 9.99899C71.8932 8.90106 72.3961 7.89136 73.4021 6.96988C74.408 6.04841 75.9761 5.58767 78.1063 5.58767C80.3942 5.58767 82.0018 6.13663 82.9288 7.23456C83.8558 8.33249 84.3193 10.0382 84.3193 12.3517V20.2921ZM80.1773 14.7044L79.8814 14.4103C79.4869 14.2142 78.8952 14.1162 78.1063 14.1162C76.3311 14.1162 75.4435 14.7044 75.4435 15.8807C75.4435 16.861 76.1338 17.3512 77.5145 17.3512C78.264 17.3512 78.8952 17.1551 79.408 16.763C79.9209 16.3709 80.1773 15.8807 80.1773 15.2926V14.7044Z" fill="#5E5E5E"/>
  <path d="M100 9.11673L95.2662 20.8802C94.9309 21.7037 94.6153 22.3703 94.3195 22.88C94.0236 23.3898 93.6291 23.8701 93.136 24.3211C92.6429 24.772 92.0413 25.0955 91.3313 25.2916C90.6212 25.4876 89.7632 25.5856 88.7573 25.5856H87.278L86.9821 25.2916V22.3507L87.278 22.0566H88.7573C89.4673 22.0566 89.9506 21.9782 90.207 21.8213C90.4634 21.6645 90.6705 21.3508 90.8283 20.8802V20.5862L86.0945 9.11673V6.17585L86.3904 5.88176H89.0531L89.349 6.17585L92.8993 16.1748H93.1952L96.7455 6.17585L97.0414 5.88176H99.7041L100 6.17585V9.11673Z" fill="#5E5E5E"/>
  <path d="M41.4225 45.7267C39.5549 45.7267 38.003 45.1125 36.7667 43.884C35.5304 42.6555 34.9122 40.9174 34.9122 38.6695C34.9122 36.4217 35.5304 34.6835 36.7667 33.455C38.003 32.2265 39.5549 31.6123 41.4225 31.6123C42.9876 31.6123 44.2107 32.0044 45.0919 32.7885C45.9731 33.5596 46.5255 34.5397 46.749 35.729L46.5518 35.925H45.7626L45.5654 35.729C45.0919 33.638 43.7109 32.5925 41.4225 32.5925C39.7785 32.5925 38.4764 33.1544 37.5163 34.2784C36.5694 35.4023 36.0959 36.866 36.0959 38.6695C36.0959 40.473 36.5694 41.9367 37.5163 43.0606C38.4764 44.1846 39.7785 44.7465 41.4225 44.7465C42.2774 44.7465 42.9876 44.6158 43.5531 44.3545C44.1318 44.0931 44.5592 43.7206 44.8354 43.2371C45.1116 42.7405 45.3023 42.2308 45.4075 41.708C45.5128 41.1853 45.5654 40.5645 45.5654 39.8457L45.3681 39.6497H40.8306L40.6334 39.4536V38.8655L40.8306 38.6695H46.5518L46.749 38.8655V45.3346L46.5518 45.5307H46.1572L45.9599 45.3346L45.7626 43.7664H45.5654C44.6842 45.0733 43.3032 45.7267 41.4225 45.7267Z" fill="#5E5E5E"/>
  <path d="M54.6441 39.4536L54.2495 39.2576H50.8958L50.6985 39.4536V45.3346L50.5012 45.5307H49.7121L49.5148 45.3346V32.0044L49.7121 31.8083H55.4332C56.9194 31.8083 58.011 32.1155 58.7081 32.7297C59.4183 33.3309 59.7734 34.2653 59.7734 35.533C59.7734 36.1341 59.6813 36.6634 59.4972 37.1208C59.3262 37.5783 59.1092 37.9376 58.8462 38.199C58.5963 38.4604 58.2938 38.676 57.9387 38.8459C57.5836 39.0158 57.2548 39.1269 56.9523 39.1792C56.6498 39.2315 56.3407 39.2576 56.0251 39.2576V39.4536L59.3788 44.7465V45.3346L59.1815 45.5307H58.787L58.3924 45.3346L54.6441 39.4536ZM57.8006 37.6697C58.3267 37.2646 58.5897 36.5523 58.5897 35.533C58.5897 34.5136 58.3332 33.8013 57.8203 33.3962C57.3205 32.9911 56.5906 32.7885 55.6305 32.7885H50.8958L50.6985 32.9845V38.0814L50.8958 38.2774H55.4332C56.4854 38.2774 57.2745 38.0749 57.8006 37.6697Z" fill="#5E5E5E"/>
  <path d="M70.0645 45.315C69.3148 45.5895 68.5125 45.7267 67.6577 45.7267C66.8028 45.7267 66.0005 45.5895 65.2508 45.315C64.5012 45.0275 63.8304 44.6093 63.2386 44.0604C62.6599 43.4985 62.1996 42.7601 61.8576 41.8452C61.5157 40.9174 61.3447 39.8588 61.3447 38.6695C61.3447 37.4802 61.5157 36.4282 61.8576 35.5134C62.1996 34.5855 62.6599 33.8471 63.2386 33.2982C63.8304 32.7362 64.5012 32.318 65.2508 32.0436C66.0005 31.7561 66.8028 31.6123 67.6577 31.6123C68.5125 31.6123 69.3148 31.7561 70.0645 32.0436C70.8141 32.318 71.4783 32.7362 72.057 33.2982C72.6488 33.8471 73.1157 34.5855 73.4577 35.5134C73.7996 36.4282 73.9706 37.4802 73.9706 38.6695C73.9706 39.8588 73.7996 40.9174 73.4577 41.8452C73.1157 42.7601 72.6488 43.4985 72.057 44.0604C71.4783 44.6093 70.8141 45.0275 70.0645 45.315ZM71.3665 34.1411C70.4327 33.1087 69.1964 32.5925 67.6577 32.5925C66.1189 32.5925 64.876 33.1087 63.9291 34.1411C62.9953 35.1736 62.5284 36.683 62.5284 38.6695C62.5284 40.656 62.9953 42.1654 63.9291 43.1979C64.876 44.2303 66.1189 44.7465 67.6577 44.7465C69.1964 44.7465 70.4327 44.2303 71.3665 43.1979C72.3135 42.1654 72.7869 40.656 72.7869 38.6695C72.7869 36.683 72.3135 35.1736 71.3665 34.1411Z" fill="#5E5E5E"/>
  <path d="M86.5912 32.0044V40.6298C86.5912 42.1981 86.1308 43.4396 85.2102 44.3545C84.2896 45.2693 83.0401 45.7267 81.4619 45.7267C79.8836 45.7267 78.6342 45.2693 77.7136 44.3545C76.7929 43.4396 76.3326 42.1981 76.3326 40.6298V32.0044L76.5299 31.8083H77.319L77.5163 32.0044V40.6298C77.5163 41.8975 77.8714 42.9038 78.5816 43.6487C79.3049 44.3806 80.265 44.7465 81.4619 44.7465C82.7245 44.7465 83.6977 44.3871 84.3816 43.6684C85.0655 42.9365 85.4075 41.9237 85.4075 40.6298V32.0044L85.6048 31.8083H86.3939L86.5912 32.0044Z" fill="#5E5E5E"/>
  <path d="M98.9347 32.7297C99.6449 33.3309 100 34.2653 100 35.533C100 36.8007 99.6449 37.7416 98.9347 38.3558C98.2377 38.957 97.1461 39.2576 95.6599 39.2576H91.1224L90.9252 39.4536V45.3346L90.7279 45.5307H89.9388L89.7415 45.3346V32.0044L89.9388 31.8083H95.6599C97.1461 31.8083 98.2377 32.1155 98.9347 32.7297ZM98.0272 37.6697C98.5533 37.2646 98.8164 36.5523 98.8164 35.533C98.8164 34.5136 98.5599 33.8013 98.047 33.3962C97.5472 32.9911 96.8173 32.7885 95.8572 32.7885H91.1224L90.9252 32.9845V38.0814L91.1224 38.2774H95.6599C96.712 38.2774 97.5012 38.0749 98.0272 37.6697Z" fill="#5E5E5E"/>
</svg>
</template>
