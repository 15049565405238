<template>
  <svg
    class="group"
    viewBox="0 0 275 275"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.355469 57.1777C0.355469 25.6975 25.8752 0.177734 57.3555 0.177734H218.355C249.836 0.177734 275.355 25.6975 275.355 57.1777V218.178C275.355 249.658 249.836 275.178 218.355 275.178H57.3555C25.8752 275.178 0.355469 249.658 0.355469 218.178V57.1777Z"
      fill="currentColor"
      :class="props.backgroundClass"
    />
    <g filter="url(#filter0_i_7146_20209)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.5767 82.5959C71.5767 79.6268 72.7562 76.7792 74.8557 74.6797C76.9552 72.5801 79.8028 71.4006 82.7719 71.4006H194.371C195.843 71.3982 197.3 71.6861 198.66 72.2477C200.02 72.8093 201.256 73.6337 202.297 74.6736C203.338 75.7135 204.164 76.9485 204.727 78.3079C205.29 79.6673 205.58 81.1245 205.579 82.5959V194.195C205.58 195.667 205.292 197.125 204.73 198.485C204.167 199.845 203.342 201.081 202.302 202.122C201.262 203.163 200.026 203.988 198.666 204.551C197.307 205.114 195.849 205.404 194.378 205.403H82.7719C81.3012 205.403 79.845 205.113 78.4863 204.55C77.1276 203.987 75.8932 203.162 74.8535 202.122C73.8139 201.081 72.9894 199.847 72.4271 198.488C71.8648 197.129 71.5759 195.672 71.5767 194.201V82.5959ZM124.617 122.492H142.762V131.604C145.381 126.366 152.082 121.651 162.15 121.651C181.452 121.651 186.027 132.085 186.027 151.229V186.691H166.493V155.591C166.493 144.688 163.874 138.536 157.222 138.536C147.994 138.536 144.157 145.169 144.157 155.591V186.691H124.617V122.492ZM91.1166 185.857H110.657V121.651H91.1166V185.851V185.857ZM113.452 100.711C113.489 102.384 113.191 104.047 112.577 105.604C111.962 107.16 111.042 108.578 109.872 109.774C108.702 110.97 107.305 111.921 105.762 112.57C104.22 113.219 102.563 113.553 100.89 113.553C99.2162 113.553 97.5596 113.219 96.0171 112.57C94.4746 111.921 93.0772 110.97 91.907 109.774C90.7368 108.578 89.8174 107.16 89.2026 105.604C88.5878 104.047 88.2901 102.384 88.3269 100.711C88.3993 97.4266 89.7546 94.3016 92.1027 92.0046C94.4508 89.7077 97.6049 88.4215 100.89 88.4215C104.174 88.4215 107.329 89.7077 109.677 92.0046C112.025 94.3016 113.38 97.4266 113.452 100.711Z"
        fill="currentColor"
        :class="props.iconClass"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_7146_20209"
        x="65.4858"
        y="65.3093"
        width="146.184"
        height="150.184"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_7146_20209"
        />
      </filter>
    </defs>
  </svg>
</template>
<script setup>
const props = defineProps({
  backgroundClass: {
    type: String,
    required: false,
    default: "text-[#2D2D2D] group-hover:text-white",
  },
  iconClass: {
    type: String,
    required: false,
    default: "text-white group-hover:text-primary",
  },
});
</script>
